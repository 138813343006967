/* ----- GENERAL STYLING ----- */
.btn-primary {
  background-color: rgba(8, 179, 248) !important;
  border-color: rgba(8, 179, 248) !important;
}

.btn-outline-primary {
  border-color: rgba(8, 179, 248) !important;
  color: white !important;
}

/* TODO: This currenlty doesn't work */
.btn-outline-primary:hover {
  border-color: rgb(7, 126, 173) !important;
}

.overview-container {
  position: relative;
}

/* ----- SIGNUP POPUP ----- */
.signup {
  position: fixed;
  bottom: 20px;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #333 !important;
}

.signup.toast {
  background-color: white !important;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.4);
}

.signup .btn.btn-primary {
  background-color: rgb(8, 179, 248) !important;
  color: white;
  border: 1px solid white !important;
  width: 80%;
}

.signup p {
  margin-bottom: 5px;
}

/* ----- PRODUCT HIGHLIGHT ----- */
.product-highlight {
  background-image: url(../assets/images/disc_golf_bg.jpeg);
}

/* ----- DOINK DEEP DIVE ----- */
.doink-deep-dive {
  padding: 45px 0px;
  background-color: rgba(8, 179, 248);
  width: 100%;
}

.doink-deep-dive-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1600px;
  margin: 0px auto;
}

.doink-features {
  display: flex;
  flex-direction: column;
}

.doink-feature__row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.doink-feature {
  flex: 1 1 0px;
  margin: 20px 20px;
}

.doink-feature-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  color: #333;
  text-align: center;
  padding: 15px 20px;
}

.doink-feature-card__image > img {
  width: 200px;
  margin-bottom: 10px;
}

.doink-feature-card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.doink-feature-card__header {
  font-weight: 600;
}

.doink-feature-card__body {
  color: #555;
}

.doink-feature-card__hint {
  font-size: 80%;
  margin-top: 10px;
  color: #777;
}

/* ----- DOINK IN ACTION ----- */
.doink-in-action {
  background-color: #fff;
  padding: 50px 0px;
  width: 100%;
}

.doink-in-action-container {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 1600px;
}

.doink-in-action-videos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}

.doink-in-action-video {
  flex-grow: 1;
  color: #444;
  padding: 10px 30px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.play-button {
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.play-button > .MuiSvgIcon-root {
  margin-top: -4px;
  margin-right: 6px;
}

/* ----- DOINK REVIEWS ----- */
.doink-reviews-section {
  background-color: rgb(8, 179, 248);
  padding: 50px 20px;
  width: 100%;
}

.doink-reviews-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  max-width: 1600px;
  margin: 0px auto;
}

.doink-reviews {
  margin-top: 25px;
  width: 100%;
  max-width: 1200px;
}

.doink-review-card {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 10px;
  color: #333;
  text-align: center;
  padding: 15px 20px;
  margin-bottom: 20px;
  width: 70%;
  align-content: center;
  justify-content: space-between;
}

.doink-review-card__header > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.doink-review-card__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.doink-review-card__body span.strong {
  font-weight: bold;
}

/* ----- DOINK CARD GAME MODES ----- */
.doink-card-game-modes {
  padding: 45px 0px;
  background-color: rgb(8, 179, 248);
  width: 100%;
  color: white;
}

.doink-card-game-modes-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1600px;
  margin: 0px auto;
  padding: 0px 10px;
}
.game-modes {
  margin: 30px 0px;
}
.game-mode {
  background-color: white;
  margin: 0px 20px;
  padding: 10px;
  border-radius: 5px;
  color: #333;
}

.game-mode-title {
  font-weight: 700;
}

/* ----- QUESTIONS ----- */
.faq-button {
  min-width: 200px;
  margin-bottom: 16px;
  color: rgb(8, 179, 248) !important;
}

.faq-button:hover {
  color: white !important;
  background-color: rgb(8, 179, 248) !important;
}

/* ----- SIGNUP ----- */
.signup-section {
  background-color: rgb(8, 179, 248);
  padding: 50px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup-section .formkit-subheader {
  visibility: hidden !important;
  margin: 0px;
}

.signup-section .formkit-subheader:before {
  content: "Receive updates on special discounts, giveaways, and more!";
  visibility: visible;
}

.signup-section .formkit-form.mills .formkit-subheader {
  margin: 5px 0px !important;
}

.signup-section .seva-fields.formkit-fields {
  margin-top: -15px !important;
}

button.formkit-submit {
  color: rgb(8, 179, 248) !important;
  background-color: white !important;
  border: 1px solid rgb(8, 179, 248) !important;
}

.footer {
	display: flex;
	background-color: #171717;
	flex-direction: row;
	justify-content: space-around;
	color: #999da2;
}

.footer-col {
	margin-top: 16px;
	flex: 1 1 0px;
	text-align: center;
}

.footer-col a {
	margin: 0px 10px;
	color: rgb(8, 179, 248);
}
.footer-col a:hover {
	color: rgba(8, 179, 248, 0.7);
}

.faq {
  height: 100%;
  /* background-color: #ddd; */
  padding: 25px;
  overflow-y: scroll;
}

.faq-container {
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
}

.row-title-text {
  font-weight: 700;
}

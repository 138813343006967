.App {
  height: 100vh;
  background-image: linear-gradient(
    rgba(8, 179, 248, 0.6),
    rgba(107, 249, 127, 0.6)
  );
  overflow: scroll;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.App::-webkit-scrollbar,
.app-card::-webkit-scrollbar {
  display: none;
}

.app-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.app-card {
  display: flex;
  max-width: 820px;
  width: 820px;
  max-height: 546px;
  height: 546px;
  flex-direction: row;
}

.card__welcome-form {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
}

.formkit-form.mills {
  border: none !important;
  background-color: #fff !important;
}

.formkit-form.mills [data-style="minimal"] {
  padding: 27px !important;
}

button.formkit-submit {
  background-color: #28a745 !important;
}

a.btn.btn-primary {
  padding: 12px 24px;
  font-size: 15px;
}

/* ----- PRODUCT HIGHLIGHT ----- */
.product-highlight {
  width: 100%;
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.product-highlight-shadow {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
}

.product-highlight-container {
  display: flex;
  flex-direction: row;
  font-size: 125%;
  max-width: 1600px;
  margin: 0px auto;
  height: 100%;
  overflow-x: hidden;
}

.highlight-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 15px;
}

.highlight-information > * {
  margin-bottom: 25px;
}

.product-highlight-section {
  display: flex;
  flex: 1 1 0px;
}
.product-highlight-section > p {
  margin-bottom: 0px;
}

.product-mockup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.product-highlight-section img {
  width: 600px;
  height: 500px;
}

.highlight-actions {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
}

.highlight-actions > button {
  min-width: 140px;
  margin-right: 15px;
}

/* ----- DOINK AD VIDEO ----- */
.doink-cards-video {
  width: 100%;
  background-color: white;
  color: #333;
}

.doink-cards-video-container {
  display: flex;
  flex-direction: row;
  max-width: 1600px;
  margin: 0px auto;
  padding: 50px 20px 60px 20px;
  width: 100%;
}

.highlight-video-section {
  flex: 1 1 0px;
  margin: auto 0px;
}

.highlight-video-section-info {
  margin-left: 80px;
}

.highlight-video-section-info > p {
  font-size: 120%;
}

.highlight-video-hint {
  font-size: 90%;
  color: rgba(8, 179, 248, 0.8);
  font-weight: 700;
}

/* ----- RECAP ----- */
.recap {
  padding: 80px 20px;
  background-color: white;
}

.recap-container {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  max-width: 1600px;
}

.recap-section {
  flex: 1 1 0px;
  padding: 0 30px;
}

.recap-call-to-action {
  display: flex;
  flex-direction: column;
}

.recap-call-to-action button {
  min-width: 270px;
  max-width: 300px;
}

.recap-contact span {
  font-weight: bold;
}

@media only screen and (max-width: 820px) {
  .App {
    height: 100vh;
    overflow-y: scroll;
  }

  .app-body {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .app-card {
    display: flex;
    flex-direction: column-reverse;
    max-height: none;
    height: 1200px !important;
    overflow-y: scroll;
  }

  .card__welcome-form {
    flex: 1;
    width: 90%;
    max-width: 410px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 321px) {
  .highlight-information {
    padding: 5px;
  }
}

@media screen and (max-width: 500px) {
  /* ----- DOINK DEEP DIVE ----- */
  .doink-feature-card__image {
    margin-bottom: 15px;
  }

  .image-card {
    flex-direction: column;
  }
}

@media screen and (min-width: 700px) {
  .tournament-director-container .highlight-video-section-video a {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  /* ----- DOINK AD VIDEO ----- */
  .doink-cards-ad {
    flex-direction: column-reverse;
  }

  .doink-cards-instructions {
    flex-direction: column;
  }

  .tournament-director-container .highlight-video-section-info a {
    display: none;
  }

  .tournament-director-container .highlight-video-section-video .video-wrapper {
    margin-bottom: 15px;
  }

  .tournament-director-container .tournament-highlight-video-section {
    text-align: center;
  }

  .highlight-video-section-info {
    margin: 0px;
    text-align: center;
  }

  /* ----- DOINK DEEP DIVE ----- */
  .doink-deep-dive {
    padding: 25px 0px;
  }

  .doink-features {
    flex-direction: column;
  }

  /* ----- DOINK IN ACTION ----- */
  .doink-in-action {
    padding: 40px 5px;
  }
  .doink-in-action-videos {
    flex-direction: column !important;
  }

  .doink-in-action-video {
    padding: 5px;
  }

  /* ----- DOINK REVIEWS ----- */
  .doink-reviews-section {
    text-align: center;
  }

  .doink-review-card {
    flex-direction: column !important;
    margin-bottom: 40px;
    width: 90%;
  }

  .doink-review-card__header > img {
    margin-bottom: 10px;
  }

  /* ----- RECAP ----- */
  .recap-container {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .recap-section {
    padding: 0px;
  }

  .recap-section:first-child {
    padding-bottom: 50px;
  }

  .recap-call-to-action {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .doink-feature__row {
    flex-direction: column;
  }

  /* ----- DOINK AD VIDEO ----- */
  .highlight-video-hint {
    display: none;
  }

  /* ----- PRODUCT HIGHLIGHT ----- */
  .highlight-information {
    text-align: center;
    padding: 10px;
    max-width: 630px;
    margin: 0px auto;
  }
  .highlight-information > * {
    margin-bottom: 20px;
  }

  .highlight-actions {
    justify-content: center;
  }

  .highlight-image {
    display: none;
  }

  .highlight-actions {
    justify-content: center;
  }
}

@media screen and (max-width: 850px) {
  .doink-feature__row {
    flex-direction: column !important;
  }

  .doink-feature-card {
    max-width: 600px;
  }
}

@media screen and (max-width: 1000px) {
  /* ----- DOINK DEEP DIVE ----- */
  .doink-deep-dive {
    padding: 50px 0px 30px 0px;
  }

  /* ----- DOINK IN ACTION ----- */
  .doink-in-action-video {
    padding: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .image-card {
    flex-direction: column;
  }

  .doink-feature-card__image {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 500px) {
  .highlight-actions > button {
    min-width: 190px;
  }
}

@media screen and (min-width: 1200px) {
  .highlight-actions > button {
    font-size: 90%;
  }

  .overview-container {
    font-size: 110%;
  }
}

@media screen and (min-width: 1300px) {
  .doink-feature-card .image-card {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 1400px) {
  .highlight-actions > button {
    min-width: 210px;
    font-size: 85%;
  }

  .overview-container {
    font-size: 130%;
  }

  .highlight-video-hint {
    font-size: 80%;
  }
}

@media screen and (min-width: 2000px) {
  .highlight-actions > button {
    min-width: 250px;
    font-size: 85%;
  }
}

.bulk-order-container {
  color: white;
  height: 100%;
  background-image: url(../assets//images/AccessCardBG.png);
}

.bulk-order-shadow {
  min-height: 375px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.bulk-order {
  height: 100%;
  flex-direction: column;
  text-align: center;
}

.bulk-order button {
  margin-top: 10px;
}

.terms-of-service-container{
    color: #333;
}

.terms-of-service-container .jumbotron{
    background-color: #fff;
    padding: 2rem;
    width: 80%;
    max-width: 900px;
    margin: 20px auto;
}

.navbar {
  background-color: white;
  font-size: 110%;
}

.navbar-brand {
  cursor: pointer;
  line-height: 2rem;
  margin-bottom: auto;
}

.navbar-brand.nav-link {
  margin: 0;
}

.nav-link {
  font-weight: 600;
  margin-left: 2px;
  color: #666 !important;
  overflow-y: hidden !important;
  padding: 8px !important;
}

.navbar .purchase-doink {
  font-style: italic;
}

.play-nav-link {
  color: rgb(8, 179, 248) !important;
}

/* ----- GENERAL ----- */
.tournament-director-container {
  height: 100%;
  background-color: white;
  font-size: 105%;
  overflow-x: hidden;
}

.tournament-director-container h1,
.tournament-director-container h2,
.tournament-director-container h3 {
  margin: 0;
}

.alert.success {
  color: white;
  background-color: #4caf50;
}

.alert.error {
  color: white;
  background-color: #f44336;
}

/* ----- PRODUCT HIGHLIGHT ----- */
.tournament-director-container .product-highlight {
  background-image: url(../assets/images/tournament2.jpg);
  background-position: 0px 25%;
}

.tournament-director-container .highlight-information h1 {
  margin-bottom: 15px !important;
}
.tournament-director-container .highlight-information p {
  margin-bottom: 5px;
  font-size: 130%;
}
.tournament-director-container .MuiButton-containedPrimary {
  color: white;
}

/* -------- EXPLANATION -------- */
.explanation {
  background-color: #08b3f8;
  color: white;
  padding: 40px;
  /* min-height: 500px; */
  text-align: center;
}

.explanation h2 {
  font-size: 250%;
  margin-bottom: 10px;
}

.explanation h3 {
  font-size: 200%;
}

.explanation-columns {
  display: flex;
  flex-direction: row;
  color: #333;
  flex-grow: 1;
}

.explanation-card {
  background-color: white;
  padding: 15px;
  color: #333;
  margin: 10px;
  font-size: 125%;
  width: 80%;
}

.explanation-card__header {
  font-weight: 700;
  margin-bottom: 5px;
}

.explanation-card__content {
  margin-bottom: 15px;
}

.explanation-card__image > img {
  height: auto;
  border-radius: 8px;
}
.explanation-card__image.vertical > img {
  width: 300px;
}
.explanation-card__image.horizontal > img {
  width: 200px;
}

/* -------- TRY FOR FREE -------- */
.try-doink-cards-container {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
}
.try-doink-cards-container h2 {
  color: #08b3f8;
}

.try-it-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  flex-grow: 1;
}

.try-it-column {
  width: 100%;
}

.try-it-column-description {
  padding: 40px;
}
.try-it-column-description > * {
  margin-bottom: 15px !important;
}

.try-it-form {
  display: flex;
  flex-direction: column;
}

.try-it-form button {
  color: #fff !important;
  font-size: 105%;
}

.MuiFormControl-root.MuiTextField-root {
  margin-bottom: 15px !important;
}

/* -------- COST ESTIMATOR -------- */
.cost-estimator {
  text-align: center;
  color: #08b3f8;
  background-image: url(../assets/images/crowd2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 42%;
}
.cost-estimator-shadow {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.75);
}

.cost-estimator-columns {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-top: 40px;
  align-items: center;
}

.cost-estimator-column {
  width: 100%;
}

.cost-estimator-column input {
  font-size: 110px;
  text-align: center;
}

/* Chrome, opera, etc */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cost-estimator-form {
  width: 200px;
  color: #08b3f8;
  margin: 0px auto;
}

.cost-estimator-form input {
  color: #08b3f8;
}

.cost-estimator-form .MuiInput-underline:before {
  border-bottom-color: #08b3f8 !important;
}

.cost-estimator-form button {
  margin-top: 15px;
  font-size: 105%;
  font-weight: 700;
}

.estimates {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.estimate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0px 10px;
  width: 90%;
  border: 1px solid #08b3f8;
}

.estimate h3 {
  text-align: left;
}
.estimate span {
  text-align: right;
}

.estimate span {
  font-size: 75px;
}

/* -------- BENEFITS -------- */
.benefits-container {
  display: flex;
  flex-direction: column;
  background-color: #08b3f8;
  color: white;
  padding: 40px;
  text-align: center;
}

.benefit-cards {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.benefit-card {
  color: #333;
  background-color: white;
  padding: 20px;
  margin: 0px 4%;
  width: 100%;
}

.benefit-card__header {
  font-weight: 700;
  font-size: 125%;
  margin-bottom: 10px;
  color: #08b3f8;
}
.benefit-card__content {
  font-size: 115%;
}

/* -------- CUSTOMIZE -------- */
.customize {
  min-height: 300px;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #08b3f8;
}

.customize-rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
}

.customize-header,
.customize-content {
  margin: 20px;
}

.customize h2 {
  font-size: 300% !important;
}

.customize h3 {
  font-size: 200% !important;
  margin-bottom: 15px;
}

.customize p {
  font-size: 120% !important;
  margin-bottom: 5px;
}

.first-customize-row {
  margin-bottom: -20px;
}

.last-customize-row {
  margin-top: -80px;
}

.customize-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.customize-column {
  margin: 20px;
}

.customize-image img {
  width: 250px;
  height: auto;
  border-radius: 8px;
}

.customize-description p {
  color: #333;
}
/* -------- TOURNAMENT DIRECTOR RECAP -------- */
.tournament-director-recap h3 {
  margin-bottom: 8px;
}
.tournament-director-recap p {
  font-size: 125%;
}

.tournament-director-recap .purchase-doink {
  color: white;
  font-size: 16px;
}

.tournament-director-recap .faq-button {
  width: 200px;
  font-weight: 400 !important;
  font-size: 16px;
}

/* -------- TOURNAMENT DIRECTOR FORM -------- */
.td-order {
  background-color: #08b3f8;
  padding: 40px;
  text-align: center;
  font-size: 105%;
}

.td-order h2 {
  color: #08b3f8;
  text-align: center;
  margin-bottom: 20px;
}

.td-order-form {
  display: flex;
  flex-direction: column;
  margin: 20px;
  text-align: left;
}

.td-order-form input,
.td-order-form button {
  font-size: 105%;
}

.td-order-form button {
  color: white !important;
}

.td-order-form input.MuiInputBase-input.MuiInput-input {
  font-size: 300%;
  text-align: center;
}

.td-order-form
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-size: 110%;
  margin-bottom: 0px !important;
}

.td-order-info {
  text-align: left;
  color: #333;
  padding: 25px;
}

.td-order-card {
  max-width: 700px;
  margin: 0px auto;
  padding: 20px;
}

.td-order-card p {
  max-width: 500px;
  margin: 10px auto 0px auto;
}

.td-order-card-note {
  max-width: 500px;
  font-size: 80%;
  margin: 10px auto 0px auto;
}

.td-order-form .MuiFormHelperText-root {
  font-size: 0.95rem;
}

@media screen and (max-width: 400px) {
  /* -------- COST ESTIMATOR -------- */
  .cost-estimator-shadow {
    padding: 20px;
  }

  /* -------- TOURNAMENT DIRECTOR FORM -------- */
  .td-order-card.card {
    padding: 10px;
  }
  .td-order-card.card h2 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  /* -------- EXPLANATION -------- */
  .explanation {
    padding: 20px;
  }

  .explanation-card.card {
    width: 95%;
  }

  .explanation-card__image.vertical > img,
  .explanation-card__image.horizontal > img {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .tournament-director-container h1 {
    font-size: 35px;
  }

  .try-doink-cards-container {
    padding: 30px 5px;
  }

  /* -------- BENEFITS -------- */
  .benefits-container {
    padding: 20px;
  }

  /* -------- COST ESTIMATOR -------- */
  .estimate span {
    font-size: 35px;
  }

  /* -------- CONTACT/RECAP -------- */
  .recap.tournament-director-recap {
    padding: 15px;
  }

  .recap.tournament-director-recap .recap-container {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 600px) {
  /* -------- CUSTOMIZE -------- */
  .customize-column.customize-description {
    width: 90%;
  }

  /* -------- COST ESTIMATOR -------- */
  .estimates {
    width: 100% !important;
  }

  /* -------- TOURNAMENT DIRECTOR FORM -------- */
  .td-order {
    padding: 20px;
  }
}

@media screen and (max-width: 800px) {
  .estimate span {
    font-size: 45px;
  }
}

@media screen and (max-width: 800px) {
  /* -------- CUSTOMIZE -------- */
  .customize-row.last-customize-row {
    margin-top: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  .customize-row.first-customize-row {
    margin-bottom: 0px;
  }
  .customize-row {
    flex-direction: column;
  }
  .customize-column.customize-description {
    margin: 15px 5px 5px 5px;
    /* width: 70%; */
  }
  .customize-column.customize-image {
    margin: 0;
  }
}

@media screen and (max-width: 850px) {
  /* -------- TRY FOR FREE -------- */
  .try-it-columns {
    flex-direction: column;
  }
  .try-it-column.try-it-column-description {
    padding: 0px 10px 10px 10px;
  }

  /* -------- BENEFITS -------- */
  .benefit-cards {
    flex-direction: column;
  }
  .benefit-card.card {
    margin: 0 0 15px 0px;
  }
}

@media screen and (max-width: 900px) {
  /* -------- COST ESTIMATOR -------- */
  .cost-estimator-columns {
    flex-direction: column;
    margin-top: 0px;
  }
  .cost-estimator-column {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1000px) {
  /* -------- BENEFITS -------- */
  .benefit-card {
    padding: 10px;
    margin: 0px 2%;
  }
}

@media screen and (max-width: 1100px) {
  /* -------- EXPLANATION -------- */
  .explanation-columns {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1100px) {
  /* -------- EXPLANATION -------- */
  .explanation-card__image.vertical > img {
    width: 90%;
  }
}

@media screen and (max-width: 1400px) {
  /* -------- CUSTOMIZE -------- */
  .customize h2 {
    font-size: 200% !important;
  }
  .customize h3 {
    font-size: 150% !important;
  }

  .customize-rows {
    flex-direction: column;
  }
  .customize-header {
    margin-bottom: 0px;
  }
  .customize-content {
    margin: 0px 0px 20px 0px;
  }

  .last-customize-row {
    margin-top: -120px;
  }
}

@media screen and (min-width: 1400px) {
  /* -------- EXPLANATION -------- */
  .card.explanation-card {
    margin: 20px;
  }
}
